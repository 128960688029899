import CtaButton from '../components/CtaButton'
import React from "react"
import { Link } from "gatsby"

const Finalctablock = () => {

    return(

<div>

<section className="lp-action-section">

    <p>Get started with a 30 day free trial of InHero, no card required</p>

    <div className="lp-action-section-btns">

         <CtaButton/>
        {/*<a href=""><button className="btn btn-sol lp-action-btn">LEARN MORE</button></a> */}
    </div>

</section>
</div>

    )
}


export default Finalctablock
