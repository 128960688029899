import React from "react"
import Layout from "../components/layout"
import solutionsearch from "../images/solution-search.svg"
import problemsearch from "../images/problemsearch.svg"
import CtaButton from '../components/CtaButton'
import reviewsfeatureone from "../images/reviews-feature-one.png"
import reviewsfeaturetwo from "../images/reviews-feature-two.png"
import solutionanalytics from "../images/solution-analytics.png"
import Finalctablock from '../components/final-cta-block'
import { Helmet } from "react-helmet"
import ogicon from "../images/og_icon.png"
import invitebox from "../images/box.svg"
import sendtext from "../images/sendtext.svg"


const index = () => {
    return(
        <Layout>

<Helmet>
    <title>InHero | Review Collection, Be the #1 choice</title>
    <meta name="description" content="InHero helps you get found, get chosen, and become the preferred local business. Get reviews before customers walk out your door. Learn how." />
    <meta property="og:title" content="InHero | Review Marketing for Local Business"/>
</Helmet>


<div className="container">
    <section className="reviews-hero">

      <div className="reviews-herotxt">
        <h1>Easily grow your local business with reviews.</h1>
        <p>
        Use InHero to collect <span className="bold">up to 10x</span> more reviews each month, making you the obvious choice in the area. 
        </p>
        <a href="">
          <CtaButton/>
        </a>
        <p className="guarantee">30 day free trial, no card required</p>
      </div>

      <img src={solutionsearch} className="reviews-heroimg" alt="" />

     
    </section>
    
  </div>



<div className="product-grad">
<div className="container">
    <section className="product-underhero">

        <div className="point reviews-point-one">
            <h4>Far more reviews</h4>
            <p>30 seconds. That's all it takes for your customer to leave a review.</p>
        </div>

        <div className="point reviews-point-two">
            <h4>Get found</h4>
            <p>More reviews increases your local SEO on Google search.</p>
           
        </div>
    
        <div className="point reviews-point-three">
            <h4>Increase your revenue</h4>
            <p>More reviews means more revenue without the ongoing and expensive advertising costs.</p>
        </div>
    </section>
</div>

</div>




<div className="container">
    <section className="features-flex smb-feature-one">
          
          
            <div className="featurestxt">
                <h3>Get more customer reviews, effortlessly</h3>
                <ul>
                    <li>-Text or Email customers review invites</li>
                    <li>-Send from your desktop, tablet or phone</li>
                    <li>-Use ready to send review templates</li>
                    <li>-Get reviews on Google, Facebook and other sites</li>
                    <li>-Mask negative reviews with tons of good reviews</li>
                </ul>  
            </div>


            <img src={sendtext} alt=""/>
        


    </section>
</div>




<div className="container ">

        <section className="productFeaturesSection">

        <img src={reviewsfeatureone} alt=""/>


        <div className="product-features-parent">
            
                <h2>Tools you’ll actually use. Results you’ll really love.</h2>
                <div className="product-features">
            
                    <div className="product-features-point product-features-point-one">
                        <h4>Quick send</h4>
                        <p>Enter your customers phone number soon after they buy, hit send and we do the rest.</p>
                    </div>



                    <div className="product-features-point product-features-point-three">
                        <h4>150+ review sites</h4>
                        <p>Choose the sites you want customers to leave a review on so you can build a balanced presence.</p>
                    </div>

                    <div className="product-features-point product-features-point-four">
                        <h4>No friction</h4>
                        <p>Send your customers directly to review sites without all the barriers.</p>
                    </div>

            </div>

        </div>
      
      



        </section>








</div>




 
            <Finalctablock />
   

        </Layout>

    )
}

export default index;

